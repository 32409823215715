import React from "react";
import { motion } from "framer-motion";

export default function CustomError({ children }) {
  return (
    <motion.span animate={{ x: [0, -4, 4, 0] }} transition={{ duration: 0.42 }}>
      {children}
    </motion.span>
  );
}
