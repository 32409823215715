import { STAGES } from "./enums";
import { saveToSS } from "./utils";

export const onSubmit = async (values, setStage) => {
  const payload = {
    name: values.name,
    experience: values.experience,
    genre: values.genre,
    software: values.software,
    hardware: values.hardware,
    link: values.link,
    email: values.email,
    tos: values.tos,
  };

  saveToSS(values);

  try {
    let [res] = await Promise.allSettled([
      fetch("https://be.blazeit.sk/api/traplife-creators", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      new Promise((resolve) => setTimeout(resolve, 800)),
    ]);

    let response = res.value;

    if (response.ok) {
      setStage(STAGES.SUCCESFUL);
      sessionStorage.clear();
    } else {
      setStage(STAGES.ERROR);
    }

    return response.ok;
  } catch (err) {
    console.log(err);
    setStage(STAGES.ERROR);
    return null;
  }
};
