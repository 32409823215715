import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion, useAnimationControls, AnimatePresence } from "framer-motion";

import "./styles.scss";

import Layout from "../Layout";
import Spinner from "./Spinner";
import CustomError from "./CustomError";
import BlazeTextInput from "./BlazeTextInput";
import { ReactComponent as ChevronDown } from "./ChevronDown.svg";

import { blazeFormSchema } from "./schemas";

import { text, STAGES } from "./enums";

import { onSubmit } from "./api";

export default function BlazeForm() {
  const formControls = useAnimationControls();
  const traplifeControls = useAnimationControls();
  const chevronControls = useAnimationControls();
  const blazeITControls = useAnimationControls();

  const [stage, setStage] = useState(STAGES.HERO);

  const animateForm = async () => {
    chevronControls.start({ opacity: 0, transition: { duration: 0.2 } });
    traplifeControls.start({ marginTop: 0, transition: { duration: 1 } });
    formControls.start({ y: 0, transition: { duration: 1 } });
  };

  const animateFinal = () => {
    traplifeControls.start({ marginTop: "42vh", transition: { duration: 1 } });
    formControls.start({
      opacity: 0,
      transition: { duration: 1 },
    });
    formControls.set({ x: "-100vw" });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    chevronControls.start({
      y: [0, 10, 0],
      transition: { repeat: Infinity, duration: 2 },
    });
  }, []);

  useEffect(() => {
    switch (stage) {
      case STAGES.HERO:
        break;
      case STAGES.FORM:
        animateForm();
        break;
      case STAGES.SUCCESFUL:
        window.scrollTo(0, 0);
        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-y-hidden");
        document.getElementById("root").classList.add("overflow-y-hidden");
        animateFinal();
        blazeITControls.start({ position: "absolute", bottom: "2rem" });
        break;
      case STAGES.ERROR:
        window.scrollTo(0, 0);
        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-y-hidden");
        document.getElementById("root").classList.add("overflow-y-hidden");
        animateFinal();
        blazeITControls.start({ position: "absolute", bottom: "2rem" });
        break;
      default:
        break;
    }
  }, [stage]);

  const submitForm = async (values) => {
    await onSubmit(values, setStage);
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Layout
        traplifeColor={"#fff"}
        blazeITColor="white"
        traplifeAnimations={{
          initial: { marginTop: "42vh" },
          animate: traplifeControls,
        }}
        blazeitAnimations={{ animate: blazeITControls }}
      >
        <p className="main-text">{renderMainText(stage)}</p>
        {STAGES.HERO && (
          <motion.div
            animate={chevronControls}
            className="w-10 h-10 cursor-pointer fill-white absolute bottom-6"
            onClick={() => {
              setStage(STAGES.FORM);
            }}
          >
            <ChevronDown />
          </motion.div>
        )}
        <Formik
          initialValues={{
            name: sessionStorage.getItem("name") || "",
            experience: sessionStorage.getItem("experience") || "",
            genre: sessionStorage.getItem("genre") || "",
            software: sessionStorage.getItem("software") || "",
            hardware: sessionStorage.getItem("hardware") || "",
            link: sessionStorage.getItem("link") || "",
            email: sessionStorage.getItem("email") || "",
            tos: false,
          }}
          validationSchema={blazeFormSchema}
          onSubmit={submitForm}
        >
          {({ isSubmitting, submitForm, status, values }) =>
            STAGES.FORM && (
              <motion.div
                initial={{ y: "50vh" }}
                animate={formControls}
                onViewportEnter={() => {
                  setStage(STAGES.FORM);
                }}
                onAnimationComplete={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Form>
                  <BlazeTextInput
                    text={text.name}
                    name="name"
                    type="text"
                    disabled={isSubmitting}
                  />
                  <BlazeTextInput
                    text={text.experience}
                    name="experience"
                    type="text"
                    disabled={isSubmitting}
                    as="textarea"
                  />
                  <BlazeTextInput
                    text={text.genre}
                    name="genre"
                    type="text"
                    disabled={isSubmitting}
                    as="textarea"
                  />
                  <BlazeTextInput
                    text={text.software}
                    name="software"
                    type="text"
                    disabled={isSubmitting}
                    as="textarea"
                  />
                  <BlazeTextInput
                    text={text.hardware}
                    name="hardware"
                    type="text"
                    disabled={isSubmitting}
                    as="textarea"
                  />
                  <BlazeTextInput
                    text={text.link}
                    name="link"
                    type="text"
                    disabled={isSubmitting}
                  />
                  <BlazeTextInput
                    text={text.email}
                    name="email"
                    type="email"
                    disabled={isSubmitting}
                  />
                  <div className="tos-wrapper">
                    <div className="tos-check">
                      <Field
                        name="tos"
                        type="checkbox"
                        id="tos"
                        disabled={isSubmitting}
                      />
                      <label htmlFor="tos">
                        Súhlasím so spracovaním{" "}
                        <a
                          href="https://blazeit.sk/pp"
                          target="_blank"
                          className="text-slate-300"
                        >
                          osobných údajov
                        </a>
                      </label>
                    </div>
                    <ErrorMessage name="tos" component={CustomError} />
                  </div>
                  <button type="submit" className="submit-button">
                    {isSubmitting ? <Spinner /> : "Odoslať formulár"}
                  </button>
                </Form>
              </motion.div>
            )
          }
        </Formik>
      </Layout>
    </AnimatePresence>
  );
}

const renderMainText = (stage) => {
  switch (stage) {
    case STAGES.HERO:
      return text.main;

    case STAGES.FORM:
      return text.main;

    case STAGES.SUCCESFUL:
      return "Ďakujeme!";

    case STAGES.ERROR:
      return (
        <>
          Nastala chyba.. <br />{" "}
          <span
            className="text-slate-300 cursor-pointer"
            onClick={() => window.location.reload(false)}
          >
            Skúsiť znova
          </span>
        </>
      );
    default:
      return null;
  }
};
