export const text = {
  main: "Veríš, že máš skills v mixovaní hudby, ktorými po boku profesionálov na stagei v klube rozpáliš crowd? Daj nám o sebe vedieť..",
  name: "Pod akým umeleckým menom si aktívny?",
  experience:
    "Ako dlho sa venuješ hudbe? Môžeš opísať, ako si sa k tomu dostal.",
  genre:
    "V akých hudobných žánroch sa orientuješ? Ktorý z nich je Tvoj obľúbený?",
  software: "Aký počítačový software používaš na mixovanie hudby?",
  hardware: "Aký hardware na mixovanie hudby ovládaš? (prehrávače/gramofóny)",
  link: "Link na Tvoj profil/Tvoju tvorbu?",
  email: "Pokiaľ máš záujem, aby sme ťa kontaktovali, napíš nám Tvoj email.",
};

export const STAGES = {
  HERO: "HERO",
  FORM: "FORM",
  ERROR: "ERROR",
  SUCCESFUL: "SUCCESFUL",
};
