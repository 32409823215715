import React from "react";
import { motion } from "framer-motion";

import TraplifeTitleAnimated from "../TraplifeTitle/TraplifeTitleAnimated";
import Powered from "../Powered";

export default function Layout({
  children,
  traplifeColor,
  blazeITColor,
  traplifeAnimations,
  blazeitAnimations,
}) {
  return (
    <>
      <motion.div
        initial={traplifeAnimations?.initial || false}
        animate={traplifeAnimations?.animate || false}
      >
        <TraplifeTitleAnimated stroke={traplifeColor} />
      </motion.div>
      {children}
      <motion.div
        initial={blazeitAnimations?.initial || false}
        animate={blazeitAnimations?.animate || false}
      >
        <Powered color={blazeITColor} />
      </motion.div>
    </>
  );
}
