import React from "react";
import { useField, Field, ErrorMessage } from "formik";
import cx from "classnames";
import { motion } from "framer-motion";

import CustomError from "./CustomError";

const vStaggerVariants = {
  initial: { opacity: 0, scale: 0.8, y: 50 },
  loaded: (id) => ({
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { delay: id * 0.08 },
  }),
  exit: {},
};

export default function BlazeTextInput({ text, id, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div
      className={cx("text-input", {
        "text-input-error": meta.touched && meta.error,
      })}
    >
      <h3>{text}</h3>
      <Field {...props} />
      <ErrorMessage name={field.name} component={CustomError} />
    </div>
  );
}
