import React from "react";

import "./style.scss";

export default function Powered({ color }) {
  return (
    <div className="powered">
      <label
        style={{
          color: color === "white" ? " white" : "black",
        }}
      >
        powered by
      </label>

      <a href="https://blazeit.sk" target="_blank">
        <img
          src={
            color === "white"
              ? require("./blazeit-white.gif")
              : require("./blazeit-black.gif")
          }
        />
      </a>
    </div>
  );
}
