import * as yup from "yup";

export const blazeFormSchema = yup.object().shape({
  name: yup.string().required("Povinné"),
  experience: yup.string().required("Povinné"),
  genre: yup.string().required("Povinné"),
  software: yup.string().required("Povinné"),
  hardware: yup.string().required("Povinné"),
  link: yup.string().required("Povinné"),
  email: yup.string().email("Neplatný formát").required("Povinné"),
  tos: yup.boolean().oneOf([true], "Povinné"),
});

/* message: yup
    .string()
    .min(10, "Message must be at least 10 characters long")
    .required("Field is required"), */
